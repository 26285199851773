import "./Pages.css";
import HeaderFront from "../components/HeaderFront";
import Clouds from "../components/Clouds";
import FrontLogo from "../components/FrontLogo";
import Footer from "../components/Footer";
import { motion } from "framer-motion";

const FrontPage = (props) => {
  const { language, updateLanguage, useDocumentTitle } = props;
  useDocumentTitle("ANDRAMA");
  return (
    <motion.div
      id="front-wrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <HeaderFront language={language} updateLanguage={updateLanguage} />
      <Clouds />
      <FrontLogo />
      <Footer language={language} />
    </motion.div>
  );
};
export default FrontPage;
