import "./Pages.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ApplyForm from "../components/ApplyForm";
import CentralLogo from "../components/CentralLogo";
import { motion } from "framer-motion";

const ApplyFormPage = (props) => {
  const { language, updateLanguage, useDocumentTitle } = props;
  useDocumentTitle("ANDRAMA - Application Form");
  return (
    <motion.div
      id="apply-form-wrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Header language={language} updateLanguage={updateLanguage} />
      <CentralLogo />
      <ApplyForm language={language} />
      <Footer language={language} />
    </motion.div>
  );
};

export default ApplyFormPage;
