import "./styles/AboutContent.css";
import {useState, useEffect, useRef} from "react";
import {dataEnglish, dataSvenska} from "../data.js";
import Dots from "../components/Dots";
import Armatura from "../images/armatura2.jpg";
import Prefab from "../images/prefab.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import {motion} from "framer-motion";

const AboutContent = (props) => {
    const {language} = props;
    const [data, setData] = useState(dataEnglish.about)
    useEffect(() =>  {
        setData(language === "English" ? dataEnglish.about : dataSvenska.about)
    }, [language])

    const s1Ref = useRef()
    const s2Ref = useRef()
    const s3Ref = useRef()
    const s4Ref = useRef()
    const s5Ref = useRef()
   
    const sectionContentAnimate = {
        hidden: {y: 200, opacity: 0},
        shown: {y: 0, opacity: 1, transition: {bounce:0.4, type: "spring", duration: 1.5}} 
    }



       return ( 
        <main >
            <section id="section-1" ref={s1Ref}>
                <div className="section-1-content-wrapper">
                    <div className="intro-img"></div>
                    <motion.div className="intro-text" variants={sectionContentAnimate} initial={"hidden"} whileInView={"shown"} viewport={{once: true, amount:0.4}}>
                        <Dots />
                        <h1><span>{data.title1}</span></h1>
                        <h5><span>{data.description}</span></h5>
                    </motion.div>
                </div>
                <div className="arrow-container">
                    <button className="arrow-down" onClick={() => s2Ref.current.scrollIntoView()}>
                        <FontAwesomeIcon icon={faAngleDown}  />
                    </button> 
                </div>
            </section>
            <section id="section-2" ref={s2Ref}>
                <motion.div className="section-2-content-wrapper" variants={sectionContentAnimate} initial={"hidden"} whileInView={"shown"} viewport={{once: true, amount:0.4}}>
                    <div className="transition-text">
                        <Dots />
                        <h1><span>{data.title2}</span></h1>
                        <h5><span>{data.values}<em>{data.valuesSpan}</em></span></h5>
                    </div>
                    <div className="transition-img">
                        <img src={Armatura} alt="Armature" />
                    </div>   
                </motion.div>
                <div className="arrow-container">
                    <button className="arrow-down" onClick={() => s3Ref.current.scrollIntoView()}>
                        <FontAwesomeIcon icon={faAngleDown}  />
                    </button> 
                </div>
            </section>
            <section id="section-3" ref={s3Ref}>
                <div className="section-3-content-wrapper">
                    <div className="concrete-img"></div>
                    <motion.div className="concrete-text" variants={sectionContentAnimate} initial={"hidden"} whileInView={"shown"} viewport={{once: true, amount:0.4}}>
                        <Dots />
                        <h1><span>{data.subtitle2}</span></h1>
                        <h5><span>{data.concrete}</span></h5>
                    </motion.div>
                </div>
                <div className="arrow-container">
                    <button className="arrow-down" onClick={() => s4Ref.current.scrollIntoView()}>
                        <FontAwesomeIcon icon={faAngleDown}  />
                    </button> 
                </div>
            </section>
            <section id="section-4" ref={s4Ref}>
                <motion.div className="section-4-content-wrapper" variants={sectionContentAnimate} initial={"hidden"} whileInView={"shown"} viewport={{once: true, amount:0.4}}>
                    <div className="prefab-text" >
                            <Dots />
                            <h1><span>{data.subtitle1}</span></h1>
                            <h5><span>{data.prefab}</span></h5>
                    </div>
                    <div className="prefab-img" >
                        <img src={Prefab} alt="Prefabricated wall" />
                    </div>
                </motion.div>
                <div className="arrow-container" onClick={() => s5Ref.current.scrollIntoView()}>
                    <button className="arrow-down" >
                        <FontAwesomeIcon icon={faAngleDown}  />
                    </button> 
                </div>
            </section>
            <section id="section-5" ref={s5Ref}>
                <div className="section-5-content-wrapper">
                    <div className="plasterboard-img"></div>
                    <motion.div className="plasterboard-text" variants={sectionContentAnimate} initial={"hidden"} whileInView={"shown"} viewport={{once: true, amount:0.4}}>
                        <Dots />
                        <h1><span>{data.subtitle3}</span></h1>
                        <h5><span>{data.plasterboard}</span></h5>
                    </motion.div>
                </div>
                <div className="arrow-container" >
                    <button title="Back to Top" className="arrow-up" onClick={() => s1Ref.current.scrollIntoView()} >
                        <FontAwesomeIcon icon={faAngleUp}  />
                    </button> 
                </div>
            </section>
        </main>
    )
}

export default AboutContent