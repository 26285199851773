import "./styles/Header.css"
import { Link } from "react-router-dom";
import {useState, useEffect, useRef} from "react"
// import SelectLang from "./SelectLang.jsx";
import {dataEnglish, dataSvenska} from "../data.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark} from "@fortawesome/free-solid-svg-icons";


const Header = (props) => {
  const {language} = props;
  const [data, setData] = useState(dataEnglish.header)
  useEffect(() =>  {
      setData(language === "English" ? dataEnglish.header : dataSvenska.header)
  }, [language])

  const showLogo = () => {
    document.getElementById("header-logo").style.display = "block"
  }

  const [burgerOpen, setBurgerOpen] = useState(false)

  const handleToggle = () => {
    setTimeout(() => {
      setBurgerOpen(prev => !prev)
    }, 250);
 
  }

  const burgerList = useRef(null)
  const onClose = () => {
    burgerList.current.classList.add("onClose")
    burgerList.current.classList.remove("onOpen")
  }
  return (
    <header>
        <div id="front-header">
          <Link className="nav-link" to="/about" onClick={showLogo} >{data.about}</Link>
          <Link className="nav-link" to="/projects" onClick={showLogo}>{data.projects} </Link>
          <Link className="nav-link" to="/contact" onClick={showLogo}>{data.contact}</Link>
          {/* <SelectLang language={props.language} updateLanguage={props.updateLanguage} ></SelectLang> */}
        </div>
        <div className="burger-menu">
          <div className="burger-btn" onClick={handleToggle}>
            {burgerOpen ? <FontAwesomeIcon icon={faXmark} onClick={onClose} /> : <FontAwesomeIcon icon={faBars} />}
          </div>
          <div className={`burger-list ${burgerOpen ? "show-menu onOpen" : ""}`} ref={burgerList} >
              <Link className="nav-link" to="/about" >{data.about}</Link>
              <Link className="nav-link" to="/projects" >{data.projects} </Link>
              <Link className="nav-link" to="/contact" >{data.contact}</Link>
              {/* <SelectLang language={props.language} updateLanguage={props.updateLanguage} /> */}
          </div>
        </div>
    </header>
  )
}

export default Header