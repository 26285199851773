import "./styles/ApplyForm.css";
import { useState, useEffect } from "react";
const ApplyForm = () => {
  useEffect(() => {
    const pingServer = () => {
      fetch("https://andrama-form-server.onrender.com/ping")
        .then((response) => response.text())
        .then((data) => console.log(data))
        .catch((error) => console.error("Error pinging server:", error));
    };

    pingServer();
    const pingInterval = setInterval(() => {
      pingServer();
    }, 5 * 60 * 1000);
    return () => clearInterval(pingInterval);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    personalNumber: "",
    swedishPersonalNumber: "",
    documentNumber: "",
    bankAccountNumber: "",
    address: "",
    phone: "",
    email: "",
    image: null,
    tshirt: "",
    jacket: "",
    pants: "",
    shoes: "",
    driversLicence: {
      A: false,
      B: false,
      BE: false,
      C: false,
      D: false,
    },
    manitouLicence: "",
    hotWorksLicence: "",
    workInHeights: "",
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const handleDriversLicenceChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      driversLicence: {
        ...prevFormData.driversLicence,
        [name]: checked,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedLicences = Object.keys(formData.driversLicence)
      .filter((key) => formData.driversLicence[key])
      .join(";");
    const formDataToSend = new FormData();
    for (const key in formData) {
      if (key === "driversLicence") {
        formDataToSend.append(key, selectedLicences);
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }
    try {
      const response = await fetch(
        "https://andrama-form-server.onrender.com/submit-form",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      if (response.ok) {
        console.log("Form submitted successfully!");
        setSuccess(true);
      } else {
        console.error("Failed to submit form");
        setError("Kļūda sūtot datus. Pārlādējiet lapu un mēģiniet vēlreiz!");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setError("Kļūda sūtot datus. Pārlādējiet lapu un mēģiniet vēlreiz!");
    }
  };

  return (
    <main>
      {success ? (
        <div className="form-sent-wrapper">
          <h2>Pieteikums nosūtīts!</h2>
          <a href="/">
            <em>Uz sākumu</em>
          </a>
        </div>
      ) : (
        <div>
          <h1>Pieteikuma anketa</h1>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div>
              <div>
                <h3>Pamatinformācija:</h3>
                <div className="input-group">
                  <label htmlFor="name">Vārds:</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="surname">Uzvārds:</label>
                  <input
                    type="text"
                    name="surname"
                    id="surname"
                    value={formData.surname}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="personal-number">Personas kods:</label>
                  <input
                    type="text"
                    name="personalNumber"
                    id="personal-number"
                    value={formData.personalNumber}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="swedish-personal-number">
                    Ja ir Zviedrijā izdots personas numurs vai koordinācijas
                    numurs:
                  </label>
                  <input
                    type="text"
                    name="swedishPersonalNumber"
                    id="swedish-personal-number"
                    value={formData.swedishPersonalNumber}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="id-number">Pases vai ID kartes numurs:</label>
                  <input
                    type="text"
                    name="documentNumber"
                    id="id-number"
                    value={formData.documentNumber}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="bank-number">Bankas konta numurs:</label>
                  <input
                    type="text"
                    name="bankAccountNumber"
                    id="bank-number"
                    value={formData.bankAccountNumber}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="adrese">Adrese:</label>
                  <input
                    type="text"
                    name="address"
                    id="adrese"
                    value={formData.address}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="phone">Telefons:</label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    value={formData.phone}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="email">E-pasts:</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="image">Dokumenta foto:</label>
                  <p>
                    Lai nodrošinātu ātru ID06 kartes izdošanu, foto attēlam
                    jābūt <strong>labas izšķirtspējas</strong>. Personai attēlā
                    jābūt{" "}
                    <strong>
                      pretskatā, ar atvērtām acīm, ar neitrālu sejas izteiksmi
                    </strong>
                    . Attēlam jābūt vienādi izgaismotam, obligāti uz{" "}
                    <strong>balta fona</strong> .
                  </p>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    name="image"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
              </div>
              <div>
                <h3>Apģērbs:</h3>
                <div className="input-group">
                  <label htmlFor="tshirt">T-krekla izmērs:</label>
                  <input
                    type="text"
                    name="tshirt"
                    id="tshirt"
                    value={formData.tshirt}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="jacket">Jakas izmērs:</label>
                  <input
                    type="text"
                    name="jacket"
                    id="jacket"
                    value={formData.jacket}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="pants">Bikšu izmērs:</label>
                  <input
                    type="text"
                    name="pants"
                    id="pants"
                    value={formData.pants}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="shoes">Apavu izmērs:</label>
                  <input
                    type="text"
                    name="shoes"
                    id="shoes"
                    value={formData.shoes}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <h3>Apliecības:</h3>
                <fieldset className="input-group">
                  <legend>Vadītāja apliecība:</legend>
                  <div className="drivers-licence-group">
                    <input
                      type="checkbox"
                      name="A"
                      id="licenceA"
                      checked={formData.driversLicence.A}
                      onChange={handleDriversLicenceChange}
                    />
                    <label htmlFor="licenceA">A</label>
                  </div>
                  <div className="drivers-licence-group">
                    <input
                      type="checkbox"
                      name="B"
                      id="licenceB"
                      checked={formData.driversLicence.B}
                      onChange={handleDriversLicenceChange}
                    />
                    <label htmlFor="licenceB">B</label>
                  </div>
                  <div className="drivers-licence-group">
                    <input
                      type="checkbox"
                      name="BE"
                      id="licenceBE"
                      checked={formData.driversLicence.BE}
                      onChange={handleDriversLicenceChange}
                    />
                    <label htmlFor="licenceB">BE</label>
                  </div>
                  <div className="drivers-licence-group">
                    <input
                      type="checkbox"
                      name="C"
                      id="licenceC"
                      checked={formData.driversLicence.C}
                      onChange={handleDriversLicenceChange}
                    />
                    <label htmlFor="licenceC">C</label>
                  </div>
                  <div className="drivers-licence-group">
                    <input
                      type="checkbox"
                      name="D"
                      id="licenceD"
                      checked={formData.driversLicence.D}
                      onChange={handleDriversLicenceChange}
                    />
                    <label htmlFor="licenceB">D</label>
                  </div>
                </fieldset>
                <fieldset className="input-group">
                  <legend>Manitou vadītāja apliecība:</legend>
                  <div className="manitou-licence-group">
                    <input
                      type="radio"
                      id="manitouLicenceYes"
                      name="manitouLicence"
                      value="ir"
                      checked={formData.manitouLicence === "ir"}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="manitouLicenceYes">Ir</label>
                    <input
                      type="radio"
                      id="manitouLicenceNo"
                      name="manitouLicence"
                      value="nav"
                      checked={formData.manitouLicence === "nav"}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="manitouLicenceNo">Nav</label>
                  </div>
                </fieldset>
                <fieldset className="input-group">
                  <legend>Darbs augstumos:</legend>
                  <div className="work-in-heights-group">
                    <input
                      type="radio"
                      id="workInHeightsYes"
                      name="workInHeights"
                      value="ir"
                      checked={formData.workInHeights === "ir"}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="workInHeightsYes">Ir</label>
                    <input
                      type="radio"
                      id="workInHeightsNo"
                      name="workInHeights"
                      value="nav"
                      checked={formData.workInHeights === "nav"}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="workInHeightsNo">Nav</label>
                  </div>
                </fieldset>
                <fieldset className="input-group">
                  <legend>“Heta Arbeten” karstie darbi</legend>
                  <div className="hot-work-group">
                    <input
                      type="radio"
                      id="hotWorksLicenceYes"
                      name="hotWorksLicence"
                      value="ir"
                      checked={formData.hotWorksLicence === "ir"}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="hotWorksLicenceYes">Ir</label>
                    <input
                      type="radio"
                      id="hotWorksLicenceNo"
                      name="hotWorksLicence"
                      value="nav"
                      checked={formData.hotWorksLicence === "nav"}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="hotWorksLicenceNo">Nav</label>
                  </div>
                </fieldset>
              </div>
            </div>
            <button type="submit">Nosūtīt</button>{" "}
            {error && (
              <div className="error-message">
                <p>{error}</p>
              </div>
            )}
          </form>
        </div>
      )}
    </main>
  );
};

export default ApplyForm;
