import "./Pages.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AboutContent from "../components/AboutContent";
// import CentralLogo from "../components/CentralLogo";
import { motion } from "framer-motion";

const AboutPage = (props) => {
  const { language, updateLanguage, useDocumentTitle } = props;
  useDocumentTitle("ANDRAMA - About Us");
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      id="about-wrapper"
    >
      <Header language={language} updateLanguage={updateLanguage} />
      {/* <CentralLogo /> */}
      <AboutContent language={language} />
      <Footer language={language} />
    </motion.div>
  );
};

export default AboutPage;
