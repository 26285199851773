import Header from "../components/Header";
import Footer from "../components/Footer";
import SubmitSuccess from "../components/SubmitSuccess";

const SubmitSuccessPage = (props) => {
    const {language, updateLanguage} = props
    return (
      <div id="submit-wrapper">
          <Header language={language} updateLanguage={updateLanguage} />
          <SubmitSuccess language={language} />
          <Footer language={language} />
      </div>
  
    )
}

export default SubmitSuccessPage