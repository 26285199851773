import "./styles/ProjectsContent.css";
import { useState, useEffect } from "react";
import { dataEnglish } from "../data.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const ProjectsContent = (props) => {
  // -------- LANGUAGE SETUP -----------
  const data = dataEnglish.projects.slides;

  // -------- CAROUSEL SETUP -----------
  const angle = 22.5;
  // const angle = 25.7143;
  const zValue = 1080;
  // const zValue = 835;
  const carouselCards = document.querySelector("[data-carousel-cards]");
  const [calcAngle, setCalcAngle] = useState(0);

  useEffect(() => {
    if (carouselCards) {
      carouselCards.style.transform = `translateZ(${zValue}px) rotateY(${calcAngle}deg)`;
    }
  }, [calcAngle, carouselCards]);

  // -------- CARD TEXT APPEARANCE SETUP -----------
  const [index, setIndex] = useState(0);
  const textContainer = document.getElementById("card-text-container");
  const toggleFadeEffect = () => {
    textContainer.classList.add("fadeIn");
    setTimeout(() => textContainer.classList.remove("fadeIn"), 500);
  };

  //--------- SWIPE SETUP FOR MOBILE VIEW ------------
  const [touchPosition, setTouchPosition] = useState(null);
  const handleTouchStart = (e) => {
    const touch = e.touches[0].clientX;
    setTouchPosition(touch);
  };
  const handleTouchMove = (e) => {
    const touch = touchPosition;
    if (touch === null) {
      return;
    }
    const current = e.touches[0].clientX;
    const difference = touch - current;
    if (difference > 7) {
      setCalcAngle((prev) => prev + angle);
      setIndex((prev) => (prev > 14 ? 0 : prev + 1));
      toggleFadeEffect();
    }
    if (difference < -7) {
      setCalcAngle((prev) => prev - angle);
      setIndex((prev) => (prev < 1 ? 15 : prev - 1));
      toggleFadeEffect();
    }
    setTouchPosition(null);
  };
  return (
    <main id="carousel-wrapper">
      <div id="horizontal-arrows">
        <FontAwesomeIcon
          icon={faAngleLeft}
          id="arrow-left"
          onClick={() => {
            setCalcAngle((prev) => prev - angle);
            setIndex((prev) => (prev < 1 ? 15 : prev - 1));
            toggleFadeEffect();
          }}
        />
        <FontAwesomeIcon
          icon={faAngleRight}
          id="arrow-right"
          onClick={() => {
            setCalcAngle((prev) => prev + angle);
            setIndex((prev) => (prev > 14 ? 0 : prev + 1));
            toggleFadeEffect();
          }}
        />
      </div>
      <div
        id="carousel"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        <div
          id="carousel-cards"
          data-carousel-cards
          style={{ transform: `translateZ(${zValue}px) ` }}
        >
          {data.length > 12 &&
            data.map((slide, idx) => {
              return (
                <div
                  className="card"
                  key={idx}
                  data-card={"card" + (idx + 1)}
                  style={{
                    transform: `rotateY(-${
                      angle * idx
                    }deg) translateZ(-${zValue}px)`,
                  }}
                >
                  <img src={slide.image} alt={slide.title} />
                </div>
              );
            })}
        </div>
        <div id="card-text-container">
          <h3>{data.length > 11 && data[index].title}</h3>
          <ul>
            {data.length > 11 &&
              data[index].description.map((line, idx) => {
                return <li key={idx}>{line}</li>;
              })}
          </ul>
        </div>
      </div>
    </main>
  );
};

export default ProjectsContent;
