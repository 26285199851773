import "./styles/CentralLogo.css";
import centralImg from "../images/logo.png";

const CentralLogo = () => {
  return (
    <div id="logo-wrapper">
      <img id="central-logo" src={centralImg} alt="ANDRAMA Logo" />
    </div>
 
  )
}

export default CentralLogo