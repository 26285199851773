import Origo1 from "./images/Origo_1.jpeg";
import Origo2 from "./images/Origo_2.jpeg";
import Origo3 from "./images/Origo_3.jpeg";
import Origo4 from "./images/Origo_4.jpeg";
import Hornet1 from "./images/Hornet_1.jpg";
import Hornet2 from "./images/Hornet_2.jpg";
import Hornet3 from "./images/Hornet_3.jpg";
import Hornet4 from "./images/Hornet_4.jpg";
import Kasernhus1 from "./images/Kasernhus_1.jpg";
import Kasernhus2 from "./images/Kasernhus_2.jpg";
import Kasernhus3 from "./images/Kasernhus_3.jpg";
import Kasernhus4 from "./images/Kasernhus_4.jpg";
import Mjolby1 from "./images/Mjolby_1.jpg";
import Mjolby2 from "./images/Mjolby_2.jpg";
import Mjolby3 from "./images/Mjolby_3.jpg";
import Mjolby4 from "./images/Mjolby_4.jpg";

export const dataEnglish = {
  header: {
    about: "About us",
    contact: "Contact us",
    projects: "Projects",
    home: "Home",
    language: {
      english: {
        name: "English",
        img: "../images/EU-flag.png",
      },
      svenska: {
        name: "Svenska",
        img: "../images/SE-flag.png",
      },
    },
  },
  about: {
    title1: "About ANDRAMA",
    title2: "Our values",
    subtitle1: "PreFab",
    subtitle2: "Concrete",
    subtitle3: "Plasterboard",
    description:
      "We are a team of professionals with rather big experience in construction business who have gathered up to achieve the best possible result in everything we do. We keep strong focus on all phases of the work starting from the very first - careful examination of drawings and finding the most effective way to achieve best workflow eliminating any delay of the project as it is one of the most crucial part of having a successful project.",
    values:
      "With strong focus on lean construction management, we believe that each project has a place for improvement both in management and sustainability besides a great quality and customer satisfaction ",
    valuesSpan: "per se.",
    prefab:
      "Nowadays prefabricated constructions can be found almost everywhere and are referred as one of the big steps towards lean construction providing the shortest time spent on site. And that means sooner moving-in date for new owners and tenants. We can provide skillful assembly team for precasted concrete elements, CLT structures or prefabricated private houses.",
    concrete:
      "Cast-in-place steel-reinforced concrete is widely used in construction from foundation to multi-story residential and commercial buildings for more than 150 years. Our team can work with all types of steel-reinforced concrete structures from plain and simple foundation and basement walls for private housing up to any size projects. Medium and big sized projects are not an issue for us at all.",
    plasterboard:
      "There is almost no construction project carried out without plasterboards nowadays. That is also one of almost no-waste processes if calculated and carried out correctly. We offer a team of skilled carpenters to build inner walls after casting or assembly of concrete walls. Or if just a team of carpenters is needed we can help.",
  },
  projects: {
    slides: [
      {
        title: "Residential building in Eskilstuna",
        description: [
          "2550m² concrete foundation",
          "115m on-site casted walls",
          "290m³ concrete casted",
          "45t reinforcement tied",
        ],
        image: Origo1,
      },
      {
        title: "Villa in Flen",
        description: [
          "1065m² reinforced slab",
          "110m on-site casted walls",
          "268m³ concrete casted",
          "18t reinforcement tied",
        ],
        image: Hornet1,
      },
      {
        title: "Residential building in Strängnäs",
        description: [
          "860m² reinforced bottom plate",
          "320m³ gravel pumped",
          "160m³ concrete casted",
          "25t reinforcement tied",
        ],
        image: Kasernhus1,
      },
      {
        title: "Hotel in Mjolby",
        description: [
          "816m² reinforced slab",
          "314m on-site casted walls",
          "318m³ concrete casted",
          "40t reinforcement tied",
        ],
        image: Mjolby1,
      },
      {
        title: "Residential building in Eskilstuna",
        description: [
          "2550m² concrete foundation",
          "115m on-site casted walls",
          "290m³ concrete casted",
          "45t reinforcement tied",
        ],
        image: Origo2,
      },
      {
        title: "Villa in Flen",
        description: [
          "1065m² reinforced slab",
          "110m on-site casted walls",
          "268m³ concrete casted",
          "18t reinforcement tied",
        ],
        image: Hornet2,
      },
      {
        title: "Residential building in Strängnäs",
        description: [
          "860m² reinforced bottom plate",
          "320m³ gravel pumped",
          "160m³ concrete casted",
          "25t reinforcement tied",
        ],
        image: Kasernhus2,
      },
      {
        title: "Hotel in Mjolby",
        description: [
          "816m² reinforced slab",
          "314m on-site casted walls",
          "318m³ concrete casted",
          "40t reinforcement tied",
        ],
        image: Mjolby2,
      },
      {
        title: "Residential building in Eskilstuna",
        description: [
          "2550m² concrete foundation",
          "115m on-site casted walls",
          "290m³ concrete casted",
          "45t reinforcement tied",
        ],
        image: Origo3,
      },
      {
        title: "Villa in Flen",
        description: [
          "1065m² reinforced slab",
          "110m on-site casted walls",
          "268m³ concrete casted",
          "18t reinforcement tied",
        ],
        image: Hornet3,
      },
      {
        title: "Residential building in Strängnäs",
        description: [
          "860m² reinforced bottom plate",
          "320m³ gravel pumped",
          "160m³ concrete casted",
          "25t reinforcement tied",
        ],
        image: Kasernhus3,
      },
      {
        title: "Hotel in Mjolby",
        description: [
          "816m² reinforced slab",
          "314m on-site casted walls",
          "318m³ concrete casted",
          "40t reinforcement tied",
        ],
        image: Mjolby3,
      },
      {
        title: "Residential building in Eskilstuna",
        description: [
          "2550m² concrete foundation",
          "115m on-site casted walls",
          "290m³ concrete casted",
          "45t reinforcement tied",
        ],
        image: Origo4,
      },
      {
        title: "Villa in Flen",
        description: [
          "1065m² reinforced slab",
          "110m on-site casted walls",
          "268m³ concrete casted",
          "18t reinforcement tied",
        ],
        image: Hornet4,
      },
      {
        title: "Residential building in Strängnäs",
        description: [
          "860m² reinforced bottom plate",
          "320m³ gravel pumped",
          "160m³ concrete casted",
          "25t reinforcement tied",
        ],
        image: Kasernhus4,
      },
      {
        title: "Hotel in Mjolby",
        description: [
          "816m² reinforced slab",
          "314m on-site casted walls",
          "318m³ concrete casted",
          "40t reinforcement tied",
        ],
        image: Mjolby4,
      },
    ],
  },
  contact: {
    text1: "Alternatively you can contact us directly via: ",
    phone: "+46 (0) 703-52 19 19",
    contactEmail: " info@andrama.se",
    text2: "Or write us to our social accounts: ",
    fullName: "FULL NAME",
    email: "EMAIL",
    message: "MESSAGE...",
    submit: "SUBMIT",
  },
  submitSuccess: {
    message: "Thank you, your message has been sent.",
    button: "Back to home",
  },
  submitError: {
    message: "Ooops, something went wrong.",
    button: "Try again",
  },
  footer: {
    copyright: "© 2024 ANDRAMA Bygg AB. All rights reserved.",
  },
};

export const dataSvenska = {
  header: {
    about: "Om oss",
    contact: "Kontakta oss",
    projects: "Projekt",
    home: "Startsida",
    language: {
      english: {
        name: "English",
        img: "../images/EU-flag.png",
      },
      svenska: {
        name: "Svenska",
        img: "../images/SE-flag.png",
      },
    },
  },
  about: {
    title1: "Om ANDRAMA",
    title2: "Våra värderingar",
    subtitle1: "Prefabricering",
    subtitle2: "Betong",
    subtitle3: "Gipsskivor",
    description:
      "Vi är ett team av proffs med ganska stor erfarenhet i byggbranschen som har samlats för att uppnå bästa möjliga resultat i allt vi gör. Vi håller starkt fokus på alla faser av arbetet med början från den allra första - noggrann granskning av ritningar och hitta det mest effektiva sättet hur man uppnår bästa arbetsflöde vilket eliminerar eventuella förseningar av projektet eftersom det är en av de mest avgörande delarna för att ha ett framgångsrikt projekt.",
    specialize:
      "Våra huvudsakliga kompetenser är inom betong- och stålprefabricerade elementmontage och platsgjuten betong. För att uppfylla kundernas behov tillhandahåller vi även montering av gipsskivor och har till och med ett team för montering av ställningar, så att våra kunder inte behöver lägga resurser på att hitta andra företag för småsaker på plats. <span>Och vi tar ansvar på allvar.</span>",
    values:
      "Svenska. olestias totam est veritatis ea enim iure, consequatur quibusdam mollitia ipsam, ad quia? Optio voluptas veritatis alias nostrum aspernatur labore excepturi at tempore, aperiam mollitia, beatae.",
    prefab:
      "Svenska. Although prefabrication in construction industry reaches back to 1830's, it is gaining more and more popularity as a more safe, cost-saving, enhanced quality and increased productivity approach in construction industry. In Sweden, 84% of detached houses are prefabricated.",
    concrete:
      "Svenska. Molestias totam est veritatis ea enim iure, consequatur quibusdam mollitia ipsam, ad quia? Optio voluptas veritatis alias nostrum aspernatur labore excepturi at tempore, aperiam mollitia, beatae, maiores culpa quo. Temporibus eaque fugit in dolor nisi doloremque itaque. Iure, adipisci totam. Iste vel quo error, cupiditate officiis expedita fugit tempora quidem non nam voluptate consectetur architecto?",
    plasterboard:
      "Svenska.Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas ad enim, praesentium ipsa perspiciatis reiciendis consectetur hic rerum voluptate ex quam facere cum aperiam laboriosam saepe culpa, officia dolores harum? Optio, beatae autem animi tempora recusandae eius laudantium rem molestiae facilis vero ab quas laborum nihil magnam qui? Natus quod debitis quo aperiam facere.",
  },
  projects: {
    slides: [],
  },
  contact: {
    text1: "Alternativt kan du kontakta oss direkt via:",
    phone: "+46 (0) 703-52 19 19",
    contactEmail: "info@andrama.se",
    text2: "Eller skriv oss till våra sociala konton: ",
    fullName: "fullständiga namn",
    email: "email",
    message: "meddelande...",
    submit: "skicka in",
  },
  submitSuccess: {
    message: "Tack! Ditt meddelande har skickats.",
    button: "Gå tillbaka",
  },
  submitError: {
    message: "Oj, något gick fel.",
    button: "Försök igen",
  },
  footer: {
    copyright: "© 2024 ANDRAMA Bygg AB. Alla rättigheter förbehållna.",
  },
};
