import { Routes, Route, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import FrontPage from "../pages/FrontPage.js";
import AboutPage from "../pages/AboutPage.js";
import ProjectsPage from "../pages/ProjectsPage.js";
import ContactPage from "../pages/ContactPage.js";
import SubmitSuccessPage from "../pages/SubmitSuccessPage";
import SubmitErrorPage from "../pages/SubmitErrorPage";
import ApplyFormPage from "../pages/ApplyFormPage";

const AnimatedRoutes = () => {
  const location = useLocation();

  // const storedLang = localStorage.getItem("language");
  const [language, setLanguage] = useState("English");
  const updateLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem("language", lang);
  };

  const useDocumentTitle = (title) => {
    useEffect(() => {
      document.title = title;
    }, [title]);
  };

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            <FrontPage
              language={language}
              updateLanguage={updateLanguage}
              useDocumentTitle={useDocumentTitle}
            />
          }
        />
        <Route
          path="/about"
          element={
            <AboutPage
              language={language}
              updateLanguage={updateLanguage}
              useDocumentTitle={useDocumentTitle}
            />
          }
        />
        <Route
          path="/projects"
          element={
            <ProjectsPage
              language={language}
              updateLanguage={updateLanguage}
              useDocumentTitle={useDocumentTitle}
            />
          }
        />
        <Route
          path="/contact"
          element={
            <ContactPage
              language={language}
              updateLanguage={updateLanguage}
              useDocumentTitle={useDocumentTitle}
            />
          }
        />
        <Route
          path="/submit-success"
          element={
            <SubmitSuccessPage
              language={language}
              updateLanguage={updateLanguage}
            />
          }
        />
        <Route
          path="/submit-error"
          element={
            <SubmitErrorPage
              language={language}
              updateLanguage={updateLanguage}
            />
          }
        />
        <Route
          path="/apply-form"
          element={
            <ApplyFormPage
              language={language}
              updateLanguage={updateLanguage}
              useDocumentTitle={useDocumentTitle}
            />
          }
        />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
