import "./styles/Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {dataEnglish, dataSvenska} from "../data.js";

const SubmitSuccess = (props) => {
    const {language} = props;
    const [data, setData] = useState(dataEnglish.submitSuccess)
    useEffect(() =>  {
        setData(language === "English" ? dataEnglish.submitSuccess : dataSvenska.submitSuccess)
    }, [language]);
  return (
    <div id="submit-success">
        {data.message}
        <div><FontAwesomeIcon icon={faThumbsUp}></FontAwesomeIcon></div>
        <Link to="/"><button>{data.button}</button></Link>
    </div>
  )
}

export default SubmitSuccess