import "./styles/Contact.css";
import { useState, useEffect } from "react";
import { dataEnglish, dataSvenska } from "../data.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Contact = (props) => {
  const { language } = props;
  const [data, setData] = useState(dataEnglish.contact);
  useEffect(() => {
    setData(language === "English" ? dataEnglish.contact : dataSvenska.contact);
  }, [language]);

  return (
    <div id="form-wrapper">
      <form
        id="contact-form"
        method="post"
        action="https://www.andrama.se/cgi-bin/FormMail.pl"
        acceptCharset="ISO-8859-1"
        encType="multipart/form-data"
        onSubmit="var originalCharset = document.charset; document.charset = 'ISO-8859-1'; window.onbeforeunload = function () {document.charset=originalCharset;};"
      >
        <input
          name="realname"
          placeholder={data.fullName}
          className="form-input"
          required
        />
        <input
          name="email"
          placeholder={data.email}
          className="form-input"
          required
        />
        <textarea
          cols="40"
          rows="10"
          name="Message"
          placeholder={data.message}
          className="form-input"
          required
        ></textarea>
        <input type="submit" value={data.submit} className="form-input" />
        <input type="hidden" name="recipient" value="info@andrama.se" />
        <input type="hidden" name="subject" value="Subject" />
        <input
          type="hidden"
          name="redirect"
          value="https://www.andrama.se/submit-success"
        />
        <input
          type="hidden"
          name="missing_fields_redirect"
          value="https://www.andrama.se/submit-error"
        />
        <input type="hidden" name="required" value="realname,email,Message" />
      </form>
      <div id="contact-info">
        <h1>{data.text1}</h1>
        <p>
          <FontAwesomeIcon icon={faEnvelope} />
          {data.contactEmail}
        </p>
        <h1>{data.text2}</h1>
        <div id="contact-social-icons">
          <a
            href="https://www.facebook.com/andramabygg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookSquare} className="link-icon" />
          </a>
          <a
            href="https://www.linkedin.com/company/andrama-bygg/about/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} className="link-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
