import "./styles/FrontLogo.css";
import centralImg from "../images/logo.png";

const FrontLogo = () => {
  return (
    <div id="front-logo-wrapper">
      <img id="front-logo" src={centralImg} alt="ANDRAMA Logo" />
    </div>
 
  )
}

export default FrontLogo