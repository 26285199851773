import Header from "../components/Header";
import Footer from "../components/Footer";
import ProjectsContent from "../components/ProjectsContent";
import { motion } from "framer-motion";

const ProjectsPage = (props) => {
  const { language, updateLanguage, useDocumentTitle } = props;
  useDocumentTitle("ANDRAMA - Projects");
  return (
    <motion.div
      id="projects-wrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Header language={language} updateLanguage={updateLanguage} />
      <ProjectsContent language={language} />
      <Footer language={language} />
    </motion.div>
  );
};

export default ProjectsPage;
