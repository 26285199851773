import "./Pages.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import CentralLogo from "../components/CentralLogo";
import { motion } from "framer-motion";

const ContactPage = (props) => {
  const { language, updateLanguage, useDocumentTitle } = props;
  useDocumentTitle("ANDRAMA - Contact Us");
  return (
    <motion.div
      id="contact-wrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Header language={language} updateLanguage={updateLanguage} />
      <CentralLogo />
      <Contact language={language} />
      <Footer language={language} />
    </motion.div>
  );
};

export default ContactPage;
