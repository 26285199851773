import "./styles/Footer.css";
import {dataEnglish, dataSvenska} from "../data.js";
import {useState, useEffect} from "react";

const Footer = (props) => {
  const {language} = props;
  const [data, setData] = useState(dataEnglish.footer)
  useEffect(() =>  {
    setData(language === "English" ? dataEnglish.footer : dataSvenska.footer)
}, [language])
  return (
    <footer>
        <div id="copyright">
            <p>{data.copyright}</p>
        </div>
    </footer>
  )
}

export default Footer