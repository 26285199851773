import "./styles/Contact.css";
import {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {dataEnglish, dataSvenska} from "../data.js";

const SubmitError = (props) => {
    const {language} = props;
    const [data, setData] = useState(dataEnglish.submitError)
    useEffect(() =>  {
        setData(language === "English" ? dataEnglish.submitError : dataSvenska.submitError)
    }, [language]);
  return (
      <div id="submit-error">
        {data.message}
        <Link to="/contact"><button>{data.button}</button></Link>
      </div>
  )
}

export default SubmitError